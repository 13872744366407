<template>
    <div class="min-h-screen flex flex-col items-center bg-gradient-to-b from-gray-50 to-gray-100">
      <main class="text-center pt-[20vh] w-full px-4" style="padding-top:40px">
        <div class="mb-12">
          <img
            src="~@/assets/logo.png"
            alt="Company Logo"
            class="mx-auto"
          />
        </div>
        <button 
          id="form-button"
          @click="openForm"
          :style="buttonStyles"
         >
          Form Request
        </button>
      </main>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  export default {
    name: "Forms",
    props: {
      id: String,
      path: String
    },
    data() {
      return {
        loading: true,
        isError: false,
        formUrl: null,
        buttonStyles: {
          backgroundColor: '#3B82F6',
          color: 'white',
          fontWeight: 'bold',
          padding: '0.75rem 2rem',
          borderRadius: '0.5rem',
          fontSize: '1.125rem',
          transition: 'background-color 300ms',
          cursor: 'pointer',
        }
      };
    },
    methods: {
      openForm() {
        if (this.formUrl) {
          window.location.href = this.formUrl;
        }
      }
    },
    created: function () {
      axios.get(`https://nck-district12-6iel3733vq-uc.a.run.app/wsn/formURL`, {
        params: {
          id: this.id,
          path: this.path
        }
      })
      .then((response) => {
        this.loading = false;
        this.formUrl = response.data.formUrl;
      })
      .catch((error) => {
        console.error(error);
        this.isError = true;
      });
    },
  };
  </script>
  
  <style scoped>
  button:hover {
    background-color: #2563EB;
  }
  </style>