import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import ReferencesComponent from "../components/Viewer.vue"
import pcReferencesComponent from "../components/pcViewer.vue"
import waReferencesComponent from "../components/waViewer.vue"
import formsComponent from "../components/forms.vue"
import wsnRefFormsComponent from "../components/wsnRefForms.vue"
import wsnSrefFormsComponent from "../components/wsnSrefForms.vue"
import pcRefFormsComponent from "../components/pcRefForms.vue"
import pcSrefFormsComponent from "../components/pcSrefForms.vue"
import waRefFormsComponent from "../components/waRefForms.vue"
import waSrefFormsComponent from "../components/waSrefForms.vue"


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: "/:id?",
    name: "references",
    component: ReferencesComponent,
    props: true,
    // props(route) {
    //   return {  id: route.query.id }
    // }
  },
  {
    path: "/wsn/:id?",
    name: "references",
    component: ReferencesComponent,
    props: true,
    // props(route) {
    //   return {  id: route.query.id }
    // }
  },
  {
    path: "/wsn/forms/:id?/:path?",
    name: "forms",
    component: formsComponent,
    props: route => ({
      id: route.params.id,
      path: route.params.path
    }),
  },
  {
    path: "/wsr/:id?/:path?",
    name: "wsnRefForms",
    component: wsnRefFormsComponent,
    props: route => ({
      id: route.params.id,
      path: route.params.path
    }),
  },
  {
    path: "/wss/:id?/:path?",
    name: "wsnSrefForms",
    component: wsnSrefFormsComponent,
    props: route => ({
      id: route.params.id,
      path: route.params.path
    }),
  },
  {
    path: "/pc/:id?/:path?",
    name: "pcRefForms",
    component: pcRefFormsComponent,
    props: route => ({
      id: route.params.id,
      path: route.params.path
    }),
  },
  {
    path: "/pcs/:id?/:path?",
    name: "pcSrefForms",
    component: pcSrefFormsComponent,
    props: route => ({
      id: route.params.id,
      path: route.params.path
    }),
  },
  {
    path: "/wa/:id?/:path?",
    name: "waRefForms",
    component: waRefFormsComponent,
    props: route => ({
      id: route.params.id,
      path: route.params.path
    }),
  },
  {
    path: "/was/:id?/:path?",
    name: "waSrefForms",
    component: waSrefFormsComponent,
    props: route => ({
      id: route.params.id,
      path: route.params.path
    }),
  },
  {
    path: "/wsn/references/:id?",
    name: "references",
    component: ReferencesComponent,
    props: true,
    // props(route) {
    //   return {  id: route.query.id }
    // }
  },
  {
    path: "/pc/:id?",
    name: "references",
    component: pcReferencesComponent,
    props: true,
    // props(route) {
    //   return {  id: route.query.id }
    // }
  },
  {
    path: "/pc/references/:id?",
    name: "references",
    component: pcReferencesComponent,
    props: true,
    // props(route) {
    //   return {  id: route.query.id }
    // }
  },
  {
    path: "/wa/:id?",
    name: "references",
    component: waReferencesComponent,
    props: true,
    // props(route) {
    //   return {  id: route.query.id }
    // }
  },
  {
    path: "/wa/references/:id?",
    name: "references",
    component: waReferencesComponent,
    props: true,
    // props(route) {
    //   return {  id: route.query.id }
    // }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
