<template>
    <div class="background-wrapper min-h-screen flex flex-col items-center">
      <main class="text-center pt-[20vh] w-full px-4" style="padding-top:80px">
        <h1 class="tyQQgH">Candidate Reference Form</h1>
        <p class="tyQQg">Thank you for taking the time to complete our reference form. Your feedback is an essential part of our recruiting process, and we truly value the insights you provide. This information will help us assess the candidate's qualifications and determine representation. While all responses are confidential, the candidate may receive notice that their overall references do not meet our standards. Additionally, a summary of the final reference report will be shared with prospective employers to assist them in their hiring decision. Our team is dedicated to representing only the best of the best, and we appreciate you helping us make that happen. Thank you!</p>
        <button 
          id="form-button"
          @click="openForm"
          :style="buttonStyles"
         >
          Let's Get Started!
        </button>
      </main>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  export default {
    name: "Forms",
    props: {
      id: String,
      path: String
    },
    data() {
      return {
        loading: true,
        isError: false,
        formUrl: null,
        buttonStyles: {
          marginTop: '20px',
          backgroundColor: 'rgb(105, 174, 173)',
          color: 'white',
          fontWeight: 'bold',
          padding: '0.75rem 2rem',
          borderRadius: '0.5rem',
          fontSize: '22px',
          transition: 'background-color 300ms',
          cursor: 'pointer',
        }
      };
    },
    methods: {
      openForm() {
        if (this.formUrl) {
          window.location.href = this.formUrl;
        }
      }
    },
    created: function () {
      axios.get(`https://nck-district12-6iel3733vq-uc.a.run.app/wsn/formURL`, {
        params: {
          id: this.id,
          path: this.path
        }
      })
      .then((response) => {
        this.loading = false;
        this.formUrl = response.data.formUrl;
      })
      .catch((error) => {
        console.error(error);
        this.isError = true;
      });
    },
  };
  </script>
  
  <style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
  .background-wrapper {
    background-image: url('~@/assets/wsn_bkg.jpg'), linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
    background-blend-mode: darken;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
    color: rgb(229, 231, 235);
  }
  @media (max-width: 768px) {  /* Tablets & smaller devices */
    .container {
      background-image: url('~@/assets/wsn_bkg.jpg'), linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)) center/cover no-repeat;
    }
  }

  .tyQQg {
    font-family: Inter, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 500;
    line-height: 33.8px;
    padding-left:24px;
    padding-right:24px;
    color: rgb(229, 231, 235);
    font-size: 26px
  }

  @media (max-width: 768px) {  /* Tablets & smaller devices */
    .tyQQg {
      font-size: 16px;
      line-height: 1.7em;
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  .tyQQgH {
    font-family: Inter, "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin-top: 10%;
    margin-bottom: 20px;
    font-size: 28px;
  }

  @media (max-width: 768px) {  /* Tablets & smaller devices */
    .tyQQgH {
      margin-top:-50px;
    }
  }

  
  button:hover {
    background-color: #2563EB;
  }
  </style>