<template>
    <div class="background-wrapper min-h-screen flex flex-col items-center">
      <main class="text-center pt-[20vh] w-full px-4" style="padding-top:80px">
        <h1 class="tyQQgH">Self-Evaluation Assessment</h1>
        <p class="tyQQg">Hi! This form is designed to help you assess your skills and performance for the role(s) you're applying for. Please answer honestly and take your time to reflect thoroughly.</p>
        <button 
          id="form-button"
          @click="openForm"
          :style="buttonStyles"
         >
          Let's Get Started!
        </button>
      </main>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  export default {
    name: "Forms",
    props: {
      id: String,
      path: String
    },
    data() {
      return {
        loading: true,
        isError: false,
        formUrl: null,
        buttonStyles: {
          marginTop: '20px',
          backgroundColor: '#60313C',
          color: 'white',
          fontWeight: 'bold',
          padding: '0.75rem 2rem',
          borderRadius: '0.5rem',
          fontSize: '22px',
          transition: 'background-color 300ms',
          cursor: 'pointer',
        }
      };
    },
    methods: {
      openForm() {
        if (this.formUrl) {
          window.location.href = this.formUrl;
        }
      }
    },
    created: function () {
      axios.get(`https://nck-district12-6iel3733vq-uc.a.run.app/wsn/formURL`, {
        params: {
          id: this.id,
          path: this.path
        }
      })
      .then((response) => {
        this.loading = false;
        this.formUrl = response.data.formUrl;
      })
      .catch((error) => {
        console.error(error);
        this.isError = true;
      });
    },
  };
  </script>
  
  <style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
  .background-wrapper {
    background-image: url('~@/assets/waBkg2.jpg'), linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
    background-blend-mode: darken;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
    color: rgb(229, 231, 235);
  }
  @media (max-width: 768px) {  /* Tablets & smaller devices */
    .container {
      background-image: url('~@/assets/waBkg2.jpg'), linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)) center/cover no-repeat;
    }
  }


  /* Mobile View */
  @media (max-width: 768px) {
    .background-wrapper {
      background-image: url('~@/assets/waBkg2Mobi.png'), linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
      background-size: cover; /* Adjust size if necessary */
      background-position: top; /* Change alignment */
      background-blend-mode: darken;
      background-repeat: no-repeat;
    }
  }

  .tyQQg {
    font-family: Inter, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 500;
    line-height: 33.8px;
    padding-left:24px;
    padding-right:24px;
    color: rgb(229, 231, 235);
    font-size: 26px
  }

  @media (max-width: 768px) {  /* Tablets & smaller devices */
    .tyQQg {
      font-size: 16px;
      line-height: 1.7em;
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  .tyQQgH {
    font-family: Inter, "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin-top: 10%;
    margin-bottom: 20px;
    font-size: 28px;
  }

  @media (max-width: 768px) {  /* Tablets & smaller devices */
    .tyQQgH {
      margin-top:-50px;
    }
  }

  
  button:hover {
    background-color: #2563EB;
  }
  </style>